//------------------------------------------------------
// Ion Components Overridden
//------------------------------------------------------
ion-toolbar {
  --background: var(--ion-color-darkgrey);
  background: var(--ion-color-darkgrey);
  border: none;
  --border-width: 0px !important;
  --min-height: 48px;
  color: var(--ion-color-mediumgrey);
}

ion-title.title-default {
  padding-inline-start: 10px;
  padding-inline-end: 0px;
  font-size: 18px;
  font-weight: normal;
  text-transform: uppercase;
  color: var(--ion-color-white);

  ion-select {
    padding-inline-start: 0px;
    width: fit-content;
  }
}

ion-content {
  --background: var(--ion-color-white);
  outline: none;

  .message-list {
    width: 100%;
    padding-top: 0px;

    ion-item {
      --padding-end: 0px;
      --inner-padding-end: 0px;
      --padding-start: 0px;
      --inner-padding-start: 0px;
    }
  }
}

ion-modal.mini-modal {
  --show-backdrop: true;
  --backdrop-opacity: var(--ion-backdrop-opacity);
}

.picker-toolbar.sc-ion-picker-ios {
  background: var(--ion-color-lightgrey);
  border-bottom: 1px solid var(--ion-color-grey);
}

.picker-col {
  padding-inline-start: 0.8em;
  padding-inline-end: 0.8em;
}

.picker-above-highlight.sc-ion-picker-ios {
  border-bottom: none;
}

.picker-below-highlight.sc-ion-picker-ios {
  top: 114px;
  height: 100px;
  border-top: none;
  background: linear-gradient(
    to top,
    var(--background, var(--ion-background-color, #fff)) 20%,
    rgba(
        var(--background-rgb, var(--ion-background-color-rgb, 255, 255, 255)),
        0.8
      )
      100%
  );
}

.picker-columns.sc-ion-picker-ios {
  perspective: unset;
}

.sc-ion-picker-ios-h {
  --background: var(--ion-color-white, --ion-color-white);
  --backdrop-opacity: var(--ion-backdrop-opacity);
  --border-color: var(--ion-color-grey);
}

ion-input {
  outline: none;
  border-radius: 4px;
  min-height: 36px;
}

.sc-ion-input-ios-h {
  --padding-start: 10px;
}

.footer-md::before {
  background-image: none;
}

ion-footer {
  --ion-background-color: var(--ion-color-lightgrey);
  --background: var(--ion-color-lightgrey);
  background: var(--ion-color-lightgrey);

  border-top: 1px solid var(--ion-color-grey);
  display: flex;
  padding: 0.6em;

  .button-solid {
    min-height: 42px;
    width: 100%;
    font-weight: normal;
  }

  .button-disabled {
    cursor: default;
    opacity: 0.2;
    pointer-events: none;
  }

  .apply-button {
    --background: var(--ion-color-softblue);
  }

  .delete-button {
    --background: var(--ion-color-delete);
  }

  .cancel-button {
    font-weight: normal;
  }
}

ion-select {
  --placeholder-opacity: 1;
  padding-left: 10px;
  padding-right: 10px;
}

ion-select {
  div .select-icon-inner {
    color: var(--ion-color-darkgrey);
    opacity: 1;
    width: 20px;
    height: 20px;
    margin: 0px 4px;
  }
}

ion-tab-bar {
  border: unset;
  --border: unset;
  padding: 0.1em 0em 0.6em;
  border-top: 1px solid var(--ion-color-grey);
  --background: var(--ion-color-lightgrey);
  --background-hover: none;
  --color-hover: none;

  .tab-selected {
    color: var(--ion-color-darkgrey);
  }

  .main-footer-svgs {
    width: 24px;
    height: 24px;
  }
}

ion-tab-button {
  font-size: 12px;
  color: var(--ion-color-mediumgrey);
  --ripple-color: var(--ion-color-lightgrey);
  --background-hover-color: var(--ion-color-white);

  ion-label {
    text-transform: uppercase;
    font-weight: normal;
  }
}

ion-row {
  align-items: center;

  ion-col {
    font-weight: normal;
    font-size: 13px;
  }
}

ion-menu-button {
  color: var(--ion-color-mediumgrey);
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}

ion-spinner {
  --color: var(--ion-color-darkgrey);
}

ion-refresher {
  background: var(--ion-color-white);
  color: var(--ion-color-darkgrey);

  ion-refresher-content {
    min-height: 180px;

    .refresher-refreshing {
      min-height: 180px;
      margin-top: 16px;

      .refresher-refreshing-icon {
        font-size: 21px;
      }

      .refresher-refreshing-text {
        color: var(--ion-color-darkgrey);
      }
    }
  }
}

#fab {
  right: 24px;
  bottom: 36px;

  ion-fab-list {
    margin-left: -7em;
    margin-bottom: 52px;

    ion-label {
      font-size: 14px;
      margin-left: 10px;
      font-weight: normal;
    }
  }
}

.fab-button {
  --background: var(--ion-color-darkgrey);
  border-radius: 28px;
  box-shadow: 0px 1px 6px 0px var(--ion-color-mediumgrey);
  width: 42px;
  height: 42px;

  ion-activated {
    --background: var(--ion-color-lightgrey);
    background: var(--ion-color-lightgrey);
    color: var(--ion-color-darkgrey);
  }
}

.fab-icon {
  color: var(--ion-color-lightgrey);
}

.fab-list {
  max-width: 190px;
  background: var(--ion-color-lightgrey);
  border-radius: 4px;
  box-shadow: 0px 1px 6px 0px var(--ion-color-mediumgrey);

  ion-button {
    --background: var(--ion-color-lightgrey);
    background: var(--ion-color-lightgrey);
    --background-hover: none;
    color: var(--ion-color-darkgrey);
    outline: none;
  }
}

.simple-toast {
  --background: var(--ion-color-steelblue);
  --color: var(--ion-color-white);
  --min-height: 60px;
  --box-shadow: 0px 1px 6px 0px var(--ion-color-mediumgrey);
  margin-top: 90px;
  text-align: center;
  font-size: 16px;
}

.error-toast {
  --background: var(--ion-color-red);
  --color: var(--ion-color-white);
  --min-height: 96px;
  --box-shadow: 0px 1px 6px 0px var(--ion-color-mediumgrey);
  margin-top: 16px;
  text-align: center;
  font-size: 24px;
  font-weight: 800;
}

ion-button {
  outline: none;
  border-radius: 4px;
  --border-radius: 4px;
  --background-hover: none;
}

.button-clear {
  --color: var(--ion-color-mediumgrey);
}

button {
  border-radius: 0px; // overwrites internal button styles
  outline: none;
}

.icon-right-of-checkbox {
  padding: 0px 0px 4px 4px;
}

.debug-info {
  float: right;
  color: var(--ion-color-mediumgrey);
  font-size: 12px;
  margin-right: 10px;
  line-height: 1.2;
}

.alert-message.sc-ion-alert-ios {
  font-size: 18px;
  padding-top: 21px;
  background: var(--ion-color-lightgrey);
}

.alert-message.sc-ion-alert-ios:empty {
  display: none;
}

.alert-wrapper.sc-ion-alert-ios {
  border-radius: 0px;
  background: var(--ion-color-white);
}

.alert-button-group {
  padding: 0px;

  .modal-alert-button {
    width: 100%;
    font-weight: normal;
    --background: none;
    --color: var(--ion-color-darkgrey);
    border-right: 0.5px solid var(--ion-color-grey);
    margin: 0px;
    padding: 0px;
    border-radius: 0px;
    --border-radius: 0px;
    text-transform: capitalize;
    font-size: 17px;
  }

  .ok-button {
    font-weight: bold;
  }
}

.alert-button.sc-ion-alert-ios {
  background-color: var(--ion-color-lightgrey);
}

.alert-head.sc-ion-alert-ios {
  --background: var(--ion-color-lightgrey);
  background: var(--ion-color-lightgrey);
}

.alert-head.sc-ion-alert-ios:empty {
  display: none;
}

.alert-checkbox-group.sc-ion-alert-ios {
  --background: var(--ion-color-white);
  max-height: unset;
}

.action-sheet-group.sc-ion-action-sheet-ios {
  border-radius: 0px;
}

.action-sheet-selected.sc-ion-action-sheet-ios {
  --button-background-selected: var(--ion-color-darkgrey);
  color: var(--ion-color-white);
  font-weight: normal;
}

.action-sheet-button.sc-ion-action-sheet-ios {
  background: var(--ion-color-white);
}

.action-sheet-cancel.sc-ion-action-sheet-ios {
  font-weight: normal;
}

.action-sheet-cancel.sc-ion-action-sheet-ios::after {
  background: var(--ion-color-lightgrey);
}

//------------------------------------------------------
// Schedule Page Styles
//------------------------------------------------------
#schedule-page {
  .center {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .filter-not-applied {
    fill: var(--ion-color-mediumgrey);
  }

  .filter-applied {
    fill: var(--ion-color-white);
  }
}

//------------------------------------------------------
// Schedule Page Styles
//------------------------------------------------------
#messages-page {
  .filter-not-applied {
    color: var(--ion-color-mediumgrey);
  }

  .filter-applied {
    color: var(--ion-color-white);
  }
}

// Add Daily Note Button next to week header event list
.add-dailynote-button {
  color: var(--ion-color-white);
  background-color: var(--ion-color-darkgrey);
  font-size: 10px;
  padding: 0px;
  margin: 0px;
  height: 18px;
  float: right;
  outline: none;
}

.button-selectEmployees-messages {
  height: 36px;
  width: 100%;
  --background: var(--ion-color-steelblue);
  font-weight: normal;
  margin: 0px;
  text-transform: capitalize;
}

//---------------------------------------------------
// Week Picker Control - Schedule Page
//----------------------------------------------------
.chooser {
  //background: var(--ion-item-background, var(--ion-background-color, #fff));
  background: var(--ion-color-lightgrey);
  color: var(--ion-color-darkgrey);
  border-bottom: 1px solid var(--ion-color-grey);

  .top {
    display: flex;
    // align-items: center;
    // width: 100%;
    min-height: 34px;

    .arrow {
      text-align: center;
      padding: 0px 22px;
      font-size: 12px;
      background: var(--ion-color-lightgrey);
      color: var(--ion-color-mediumgrey);
      outline: none;
    }

    .month-year-container {
      text-align: center;
      flex: 1;
      //text-transform: uppercase;
      font-size: 15px;

      .datetime-picker-container {
        width: 100%;
        display: block;

        .datetime-picker {
          display: inline-block;
          vertical-align: middle;
          color: var(--ion-color-darkgrey);
          padding: 7px;
        }
      }
    }
  }

  .weekday-list {
    display: flex;
    width: 100%;
    padding: 0px 0px 4px;


    .weekday-button {
      flex: 1;
      text-align: center;
      padding: 0px;
      background: var(--ion-color-lightgrey);
      color: var(--ion-color-darkgrey);
      //text-transform: uppercase;
      font-size: 12px;

      .today {
        border-bottom: 1px solid var(--ion-color-mediumgrey);
        margin: 1px 12px;
      }

      .not-today {
        border-bottom: 1px solid var(--ion-color-lightgrey);
        margin: 1px 12px;
      }

      .selected-date {
        font-weight: bold;
      }
    }
  }
}

//---------------------------------------------------
// CARDS
//---------------------------------------------------
.card {
  padding: 3px;
  font-family: Helvetica, Arial, sans-serif;
  align-items: center;
  background-color: var(--ion-color-white);
  color: var(--ion-color-darkgrey);
  width: 100%;
  border-bottom: 1px solid #ddd;

  .card-outer {
    font-size: 12px;
    line-height: 1.2;
    margin-left: 10px;
    text-align: left;
    padding-left: 6px;
    white-space: nowrap;

    .card-name-col {
      min-width: 58px;
      max-width: 58px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .card-duration-col {
      min-width: 36px;
      max-width: 36px;
      text-align: right;
    }

    .card-start-col {
      min-width: 48px;
      max-width: 48px;
      text-align: right;
    }

    .card-end-col {
      min-width: 48px;
      max-width: 48px;
      text-align: right;
    }

    .card-position-col {
      padding-left: 10px;
      width: 100%;

      // Below is what allows position column to stretch within display.
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;

      .break-b {
        min-width: 18px;        
      }

      .break-start {
        text-align: right;
      }

      .break-dash {
        min-width: 10px;
        text-align: center;
      }

      .break-end {
        text-align: right;
      }

      .break-paid {
        min-width: 15px;
        text-align: center;
      }

      .break-restricted {
        min-width: 12px;
        text-align: center;
      }
    }

    .break-div {
      color: var(--ion-color-darkgrey);
      margin: 3px 0px;
      width: 100%;
    }
  }
}

//--------------------------------------------------
// Daily Notes
//---------------------------------------------------
.daily-note {
  font-family: Helvetica, Arial, sans-serif;
  background-color: var(--ion-color-white);
  color: var(--ion-color-darkgrey);
  width: 100%;
  text-align: left;
  padding: 0 13px 8px;

  .daily-note-text-row {
    padding-top: 2px;
    white-space: pre-line;
    line-height: normal;
  }
}

// Edit Daily Note Modal
.edit-daily-modal-content {
  ion-textarea {
    border: 1px solid var(--ion-color-grey);
    margin: 0;
    padding: 0;
    display: block;
    color: var(--ion-color-darkgrey);
    font-weight: normal;
  }
}

.character-counter {
  float: right;
  margin: 4px;
  color: var(--ion-color-mediumgrey);
}

//--------------------------------------------------
// Events
//--------------------------------------------------
.event-list-header {
  color: var(--ion-color-darkgrey);
  padding: 14px 12px 8px;
  font-weight: normal;
  font-size: 14px;
  display: block;
  outline: none;
  //text-transform:capitalize;
}

.event-list-header:focus {
  animation: highlight 1500ms ease-out;
}
@keyframes highlight {
  0% {
    background-color: var(--ion-color-grey);
  }
  100% {
    background-color: var(--ion-color-white);
  }
}

//---------------------------------------------------
// Edit Event Modal
//---------------------------------------------------
.outer-content {
  font-size: 13px;

  ion-row {
    ion-col {
      .label {
        color: var(--ion-color-mediumgrey);
      }
      P .select {
        border: 1px solid var(--ion-color-grey);
        padding: 5px;
        margin: 0;
      }

      // Event Notes
      .textarea {
        margin: 0px auto;
        min-height: 50px;
        padding: 8px;

        .textarea-wrapper {
          textarea {
            padding: 0px;
            padding-inline-start: 0px;
            padding-inline-end: 0px;
          }
        }
      }

      .input-textbox {
        border: 1px solid var(--ion-color-grey);
        height: 32px;
      }

      .input-textbox-error {
        border: 1px solid var(--ion-color-red);
        height: 32px;
      }
    }
  }

  .shadow-event-label {
    margin-left: 10px;
  }

  .grid {
    .current-event-row {
      .current-event-col {
        display: inherit;
      }
    }
  }
}

.invisible {
  display: none;
}

.request-button {
  margin: 20px 15px 0 10px;
  font-size: 16px;
}

// Weekday List
//---------------------------------------------------
.weeklist-container {
  display: flex;

  .day {
    margin: auto;
    text-align: center;

    .day-checkbox {
      display: flex;
      margin: 6px 0px;
    }
  }
}

// Recurring Event Section
//---------------------------------------------------
.alert-wrapper {
  max-width: 80% !important;
  max-height: 80% !important;
}

// Event Request Modal
//---------------------------------------------------
.usage-message {
  margin: 20px 10px 10px 10px;
  font-size: 18px;
}

// Event Break Editor
//---------------------------------------------------
.add-break-button {
  --background: var(--ion-color-lightgrey);
  width: 40px;
  --box-shadow: none;
  border: 1px solid var(--ion-color-grey);
}

.break-div {
  color: var(--ion-color-darkgrey);
  margin: 6px 0px;
  width: 96%;

  .paid-icon {
    padding-left: 3px;
  }

  .restricted-icon {
    padding-left: 3px;
  }

  button {
    position: relative;
    float: right;
  }
}

// Error Message in Modal
//---------------------------------------------------
.error-message {
  color: red;
  font-size: 16px;
  text-align: center;
}

//---------------------------------------------------
// MODAL PAGES
//---------------------------------------------------
.modal-wrapper {
  --border-radius: 0px;
  min-width: 250px;
}

.mini-modal .modal-wrapper {
  max-width: 80%;
  max-height: 80%;
}

.add-break-modal .modal-wrapper {
  height: 100%;
}

.detail-modal .modal-wrapper {
  height: 40%;
}

.clockout-modal .modal-wrapper {
  height: 40%;
}

.break-selector-modal .modal-wrapper {
  height: 50%;

  .apply-button {
    margin: 10px;
  }
}

.event-request-modal .modal-wrapper {
  height: 50%;
}

.recurrence-update-modal .modal-wrapper {
  height: 70%;
}

.orbital-dialog-modal .modal-wrapper {
  height: 30%;
  width: 70%;
}

.modal-shadow {
  opacity: 0.6 !important;
  background: transparent !important;
}

.label {
  // font-size: 14px;
  font-weight: normal;
  // margin: 14px 0px 10px;
  color: var(--ion-color-mediumgrey);
}

ion-checkbox {
  padding: 0;
  margin: 4px;
  --border-radius: 4px;
  --border-width: 1px;
  --border-color: var(--ion-color-grey);
  width: 24px;
  height: 24px;
}

.checkbox {
  padding: 0;
  margin: 5px;
  --border-width: 1px;
  // transform: scale(1.5);
}

.alert-checkbox-icon.sc-ion-alert-ios {
  border-radius: 4px;
  border-width: 1px;
  border-color: var(--ion-color-grey);
  margin-left: unset;
  margin-right: unset;
  -webkit-margin-start: 16px;
  margin-inline-start: 16px;
  -webkit-margin-end: 6px;
  margin-inline-end: 6px;
}

.select {
  border: 1px solid var(--ion-color-grey);
  border-radius: 4px;
  padding: 8px;
  margin: 0;
  color: var(--ion-color-darkgrey);
}

.input {
  border: 1px solid var(--ion-color-grey);
  border-radius: 4px;
  padding: 5px;
  margin: 0;
}

.textarea {
  border: 1px solid var(--ion-color-grey);
  border-radius: 4px;
}

.native-textarea {
  min-height: 50px; // CreateMessageModal IonTextarea
  // padding-inline: 0px;
}

button:disabled > div > div.alert-radio-icon {
  visibility: hidden;
}

.clockout-button {
  font-weight: normal;
  --background: var(--ion-color-red);
}

//---------------------------------------------------
// CLOCK PAGE
//---------------------------------------------------
.label-list-header {
  font-size: 16px;
  font-weight: normal;
  margin: 14px 0px 10px;
  color: var(--ion-color-darkgrey) !important;
}

.item {
  // --background: #fff;
  --highlight-height: 0; //Removes bottom highlight on daily note textarea in modal.
}

// .item:last-child {
//   --border-style: none;
// }

// Removes line on bottom of Item
//--------------------------------
.item-no-border {
  --border-style: none;
}

#clock-page {
  height: 100%;

  ion-list-header {
    font-weight: normal;
  }

  .scroll {
    background: #ededed;
  }

  .todaylist {
    margin-bottom: 110px;
    background: var(--ion-color-white);
  }

  .center {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

//---------------------------------------------------
// CLOCK: PAY PERIOD TAB
//---------------------------------------------------
.list-header {
  border-bottom: 1px solid var(--ion-color-grey);
}

.list-footer {
  border-top: 1px solid var(--ion-color-grey);
}

.payperiod-list-buttons {
  font-size: 14px;
  background-color: var(--ion-color-white);
  border: none;
  outline: none;
  padding: 0px;
}

//---------------------------------------------------
// CLOCK FOOTER PANEL
//---------------------------------------------------
.clock-controls {
  padding: 0px;
  margin: -5px -10px;
  font-weight: normal;

  .clockin-button {
    display: flex;
    margin: 0px auto;
    font-size: 14px;
    --background: var(--ion-color-clock);
  }

  .clockout-button-container {
    display: flex;
    padding: 0px 3px;

    .clockout-button {
      font-size: 14px;
      --background: var(--ion-color-red);
    }

    .break-button {
      font-size: 14px;
      --background: var(--ion-color-payroll);
    }
  }
}

// CLOCK FOOTER: Map
//---------------------------------------------------
.map-button {
  display: flex;
  padding: 0px;
  margin: 0px auto;
  --background: var(--ion-color-steelblue);
  --background-activated: var(--ion-color-steelblue);
  --background-focused: var(--ion-color-steelblue);
}

.map-button-padding {
  padding: 0px 0px 5px;
}

#show-map-button {
  position: absolute;
  right: 0;
}

#map-container {
  height: 150px;
  border: 1px solid var(--ion-color-grey);
  margin-top: -3px;

  .map-canvas {
    height: 100%;
    background-color: orange;
    opacity: 0;
    transition: opacity 250ms ease-in;
  }

  .show-map {
    opacity: 1;
  }
}

//---------------------------------------------------
// MESSAGE: View Message Modal
//---------------------------------------------------
.request-button {
  margin: 20px 15px 0 10px;
  font-size: 20px;
  height: 50px;
}

.outer-contentMessage {
  color: var(--ion-color-darkgrey);

  .messageIonRow-header {
    padding: 2px 10px;
    display: block;
    font-size: 14px;

    .messageHeaderSubject {
      white-space: nowrap;
      display: inline-block;
      margin: 0px;
      padding: 4px 2px;
    }

    .messageHeaderDetails {
      display: inline-block;
      margin: 0px;
      padding: 2px;
    }

    .readCounter {
      text-align: right;
      float: right;
    }
  }

  .messageIonRow-content {
    line-height: 1.4;
    margin: 8px;
    display: block;

    .messageBlock {
      display: inline-block;
      padding-top: 14px;
      font-size: 14px;
      border-top: 1px solid var(--ion-color-grey);
    }
  }
}

//---------------------------------------------------
// Message Item
//---------------------------------------------------
.message {
  width: 100%;
  padding: 10px 12px;
  background-color: var(--ion-color-white);
  color: var(--ion-color-darkgrey);
  line-height: normal;
  text-align: left;
  white-space: nowrap;

  .top-line {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    // white-space: nowrap;

    .subject {
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .time {
      float: right;
      padding: 0px 4px;
    }
  }

  .bottom-line {
    width: inherit;
    color: var(--ion-color-darkgrey);
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    // white-space: nowrap;
  }
}

//---------------------------------------------------
// Time Overview Display
//---------------------------------------------------
.segment-content {
  margin: 0 15px 0 15px;
}

//---------------------------------------------------
// No Messages or Events to Show
//---------------------------------------------------
.no-messagesOrEvents-text {
  margin: 10px 18px;
  display: block;
  font-size: 14px;
  font-style: italic;
  color: var(--ion-color-mediumgrey);
}

//---------------------------------------------------
// Option Selector
//---------------------------------------------------
.option-selector {
  position: relative;
  float: right;
  .option {
    height: 40px;
    border: 1px solid darkgrey;
    border-radius: 5px;
    padding: 0 25px;
    margin-left: 5px;
  }

  .selected-option {
    background-color: darkgrey;
  }
}

//---------------------------------------------------
// PAGES
//---------------------------------------------------

// Main Tabs
//---------------------------------------------------
.hasMessages {
  font-size: 10px;
  color: white;
  background-color: red;
  position: fixed;
  margin-top: -10px;
  margin-left: 25px;
  border-radius: 100%;
  padding: 2px 6px;
  z-index: 100;
}

.noMessages {
  visibility: hidden;
  display: none;
}

// Login
//---------------------------------------------------
.login-content {
  --background: var(--ion-color-darkgrey);
  border: none;
  outline: none;

  .login-content-container {
    margin: 0% auto;
    max-width: fit-content;
    padding: 0.6em;
  }

  .login-logo img {
    margin: 8% auto 3%;
  }

  .form-list {
    background-color: var(--ion-color-darkgrey);

    .item {
      .label {
        color: var(--ion-color-mediumgrey);
        margin: 0px auto;
        font-size: 14px;
      }

      .field-container {
        margin: 6px auto 12px;

        .input {
          height: 42px;
          padding-left: 6px;
          width: 100%;
          color: var(--ion-color-darkgrey);
        }
      }
    }
  }

  .login-button {
    margin: 10% auto 8%;
    min-height: 50px;
    --background: var(--ion-color-steelblue);
  }

  .button-disabled {
    cursor: default;
    pointer-events: none;
  }

  .forgot-password-link {
    text-decoration: none;
    float: right;
    color: var(--ion-color-white);
    cursor: pointer;
    font-size: 14px;
  }
}

.login-footer {
  background: var(--ion-color-darkgrey);
  display: block;
  font-size: 12px;
  margin: 0% auto;
  border: none;

  .divider-line {
    border-bottom: 0.5px solid var(--ion-color-mediumgrey);
  }

  .legal-statement {
    margin: 6px auto;
    color: var(--ion-color-mediumgrey);
    line-height: 1.4;

    .terms-privacy-link {
      text-decoration: none;
      color: var(--ion-color-white);
      cursor: pointer;
    }
  }

  .copyright {
    margin: 6px auto;
    color: var(--ion-color-mediumgrey);
  }
}

// Account Page
//---------------------------------------------------
#account-page {
  img {
    max-width: 140px;
    border-radius: 50%;
  }
}

// Menu Page
//---------------------------------------------------
.username-header {
  margin: 10px;
  font-size: 17px;
}

.menu-button-background {
  ion-item {
    --background: var(--ion-color-white);
  }

  ion-list {
    background: var(--ion-color-white);
  }

  ion-icon {
    color: var(--ion-color-darkgrey);
  }
}

// ion-menu::part(backdrop) {
//   opacity: var(--ion-backdrop-opacity);
// }

// ion-menu {
//   --backdrop-opacity: var(--ion-backdrop-opacity);
// }

// Splash
//---------------------------------------------------
#splash {
  --background: var(--ion-color-grey);
  border: none;
  outline: none;

  .login-logo img {
    margin: 4% auto;
    // max-width: 86%;
    // filter: brightness(0) invert(1);
    display: block;
  }

  footer {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: x-small;
  }
}

.report-chooser-select {
  font-size: 10px;
  padding: 0px;
  margin: 0px;
  height: 18px;
  float: right;
  outline: none;
}

// PunchClock

#punchclock-entry-page {
  background-color: gray;
}

.punchclock-numpad-container {
  background-color: white;
}

.punchclock-numpad-button {
  min-height: 64px;
  min-width: 32px;
  font-size: 32px;

  @media screen and (min-width: 440px) {
    min-height: 84px;
    min-width: 64px;
    font-size: 48px;
  }

  @media screen and (min-width: 550px) {
  }

  @media screen and (min-width: 650px) {
    min-width: 180px;
    min-height: 100px;
  }


  @media screen and (min-width: 900px) {
    font-size: 64px;
    min-width: 240px;
    min-height: 120px;
  }
}

#punchclock-backbutton {
  width: 24px;
  filter: invert(100%);

  @media screen and (min-width: 321px) {
  }

  @media screen and (min-width: 550px) {
  }

  @media screen and (min-width: 900px) {
  }
}

.punchclock-numpad-submit-button {
  width: 100%;
  height: 2.5em;
  font-size: 2em;

  @media screen and (min-width: 440px) {
    font-size: 2.5em;
  }

  @media screen and (min-width: 550px) {
    font-size: 4.4em;
  }
}

.punchclock-settings {
  font-size: 22px;

  ion-input {
    margin: 5px 10px 25px 40px;
    font-size: 22px;
    border: 1px solid lightgray;
  }

  .punchclock-settings-label {  
    color: var(--ion-color-mediumgrey);  
  }
}

.punchclock-numpad-col {
  text-align: center;
}

punchclock-numpad-row {
  text-align: center;
}

ion-modal.reenter-login-modal {
  --background: var(--ion-color-darkgrey);
  --show-backdrop: true;
  --backdrop-opacity: 90%;
}

.reenter-login-modal .modal-wrapper {
  max-width: 85%;
  max-height: 60%;
  min-height: 470px;
}
