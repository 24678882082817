@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-alpine {
  @include ag-theme-alpine((
    // use theme parameters where possible
    // alpine-active-color: deeppink
  ));

  display: flex;
  flex-direction: column;
  height: 100%;

  .data-display-container {
    //flex: 1 1 auto;    // iOS: Cannot use flex on container containing ag-grid
    height: 95%;
  }

  .data-footer-container {
    //flex: none;       // iOS: Cannot use flex on container containing ag-grid
    height: 33px;
    border: 0px;
    background-color: var(--ion-color-lightgrey);

    .data-footer-container-inner {
      height: 100%;
    }
  }

  .ag-header {
    background-color: var(--ion-color-lightgrey);
    border-bottom: 1px solid var(--ion-color-grey);



    .ag-header-cell {
      // padding: 3px;
      line-height: 32px;
    }
  }

  .ag-header-row {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 32px;
  }

  .ag-cell {
    // padding: 3px;
    font-size: 12px;
    // margin-top: -5px;
    border: none;
    line-height: 32px;
  }

  .ag-root-wrapper {
    border: 0px;
    border: var(--ion-color-lightgrey);
    border-color: var(--ion-color-lightgrey);
    // background-color: var(--ion-color-lightgrey); ////asdfasdfasd
  }

  .ag-row {
    border-style: none;
    height: 32px;

    .ag-row-last {
      border: none;
      border-bottom-style: none;
    }
  }

  .ag-row-level-0 {
    background-color: var(--ion-color-lightgrey);
  }

  .ag-row.ag-row-last {
    border: none;
  }

  .ag-row-odd {
    background-color: var(--ion-color-white);
  }

  .ag-row:not(.ag-row-first) {
    border-style: none;
  }


  .ag-floating-top {
    border-bottom-width: 0px;
  }

  .ag-full-width-container {
    height: 100%;
  }
}

